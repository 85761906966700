import { h } from 'preact';
import { useState } from 'preact/hooks';
import htm from 'htm';

import { palette } from '../branding';
import { Band } from '../atoms/band';
import { Section, SectionHead } from '../molecules/section';
import { Shield, LogoText } from '../atoms/logo';
import { SocialFacebook, SocialInstagram } from '../atoms/social-media';
import { ImageList } from '../molecules/image-list';
import { BuyTicketsButton } from '../molecules/buy-tickets';

import homeHead from './home-head.jpg';

const html = htm.bind(h);

const partners = [ /* {
	img: require('../img/partners/nua.png'),
	url: 'https://norwichuni.ac.uk',
	name: 'Norwich University of the Arts',
} */ ];

const charities = [ /* {
	img: require('../img/charities/tbf.png'),
	url: 'https://benjaminfoundation.co.uk',
	name: 'The Benjamin Foundation',
} */ ];

const sponsors = [ /* {
	img: require('../img/sponsors/evil-genius-games.png'),
	url: 'https://evilgeniusgames.com',
	name: 'Evil Genius Games',
} */ ];

const exhibitors = [ /* {
	img: require('../img/exhibitors/Abi_Stevens_Art.png'),
	name: 'Abi Stevens Art',
	url: 'https://www.abistevens.com',
} */ ].sort((a, b) => a.name.replace(/^(The|A) /, "") > b.name.replace(/^(The|A) /, "") ? 1 : -1);

import backgroundPrg from '../img/background-get-program.jpg';
const OpenProgramButton = () => {
	const [hover, setHover] = useState(false);

	const styles = {
		normal: {
			'font-family': 'Planet Gamers',
			'font-size': 40,
			'text-align': 'center',
			'text-decoration': 'none',
			'font-weight': 'normal',
			color: palette.primary,
			'min-height': '1em',
			width: '9em',
			'max-width': '95%',
			'padding': '0.5em 0em 0.3em 0em',
			background: `rgba(255, 255, 255, 0.9)`,
			cursor: 'pointer',
			display: 'block',

			'-webkit-user-select': 'none',
			'-moz-user-select': 'none',
			'-ms-user-select': 'none',
			'user-select': 'none',

			transition: 'color 0.5s',
		},
	};
	styles.hover = {
		...styles.normal,
		color: palette.primary2,
	};
	const style = hover ? styles.hover : styles.normal;

	const backstyle = {
		display: 'flex',
		'justify-content': 'center',
		'flex-direction': 'column',
		'align-items': 'center',
		'min-height': 80,
		padding: '20px 0px',

		width: '100%',
		height: '100%',
		background: `url(${backgroundPrg}) no-repeat center center ${palette.accent}`,
		'background-size': 'cover',
	};
	return html`<div style=${backstyle}>
		<a
			style=${style}
			onMouseOver=${() => setHover(true)}
			onMouseLeave=${() => setHover(false)}
			href="https://www.norwichgames.uk/files/2024/program.pdf"
			target="_blank"
		>
			2024 ProgrAm
		</a>
	</div>`
};

export default () => {
	const nextStyle = {
		color: palette.secondary,
		'font-family': 'Planet Gamers',
		'font-size': 24,
	};

	const locationFrameStyle = {
		display: 'block',
		width: '100%',
		height: 400,
	};

	const socialLineStyle = {
		display: 'flex',
		'justify-content': 'center',
		'align-items': 'center',
		'flex-direction': 'row',
		'flex-wrap': 'nowrap',
		'text-align': 'center',
		gap: 20,
		'margin-top': 20,
	};

	const centerStyle = { width: '100%' };

	const promoReelStyle = {
		width: "100%",
		'aspect-ratio': '1280 / 720',
	};

	/*
		<${BuyTicketsButton} />
		<${OpenProgramButton} />
		<${Section}>
			<${SectionHead} text="PArtnErs" />
			<${ImageList} items=${partners} width=${200} justifyContent="space-evenly" />
		</${Section}>
		<${Section}>
			<${SectionHead} text="CharIty PArtnErs" />
			<${ImageList} items=${charities} width=${200} justifyContent="space-evenly" />
		</${Section}>
		<${Section}>
			<${SectionHead} text="Sponsors" />
			<${ImageList} items=${sponsors} width=${200} justifyContent="space-evenly" />
		</${Section}>
		<${Section}>
			<${SectionHead} text="ExhIbIt­ors" />
			<${ImageList} items=${exhibitors} background="white" minItems=${3} keepSquare />
		</${Section}>
	*/
	return html`
		<${Band} />
		<${Section} bigbanner backgroundImage=${homeHead}>
			<center style=${centerStyle}>
				<${Shield} width=180 />
				<${LogoText} />
				<p style=${nextStyle}>Saturday 23rd of<br />August 2025</p>
				<div style=${socialLineStyle}>
					<${SocialFacebook} />
					<${SocialInstagram} />
				</div>
			</center>
		</${Section}>
		<${Section} alt>
			<iframe
				style=${promoReelStyle}
				src="https://www.youtube.com/embed/jSo9FJqpE-g?si=14ee3gPP02-jrSw0"
				title="YouTube video player"
				frameborder="0"
				allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				allowfullscreen
			></iframe>
		</${Section}>
		<${Section}>
			<iframe
				style=${locationFrameStyle}
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2421.256676470736!2d1.2938408766882445!3d52.63727557209209!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d9e3db64bbbfdb%3A0x1c11218f280da546!2sEpic%20Studios!5e0!3m2!1sen!2suk!4v1739016124048!5m2!1sen!2suk"
				frameborder="0"
				scrolling="no"
				marginheight="0"
				marginwidth="0"
			></iframe>
		</${Section}>
		<${Band} />
	`;
};
