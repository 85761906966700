import Home from './pages/home';
import About from './pages/about';
import Contact from './pages/contact';
//import WhatsOn from './pages/whats-on';
import GetInvolved from './pages/get-involved';
//import FreeStuff from './pages/free-stuff';
import FAQ from './pages/faq';

export default [
	{ text: "Home", path: "/", page: Home },
	{ text: "What's On", path: "/whats-on", /* page: WhatsOn */ disabled: true },
	{ text: "Free Stuff", path: "/free-stuff", /* page: FreeStuff */ disabled: true },
	{ text: "Get Involved", path: "/get-involved", page: GetInvolved },
	{ text: "About", path: "/about", page: About },
	{ text: "Contact", path: "/contact", page: Contact },
	{ text: "FAQ", path: "/faq", page: FAQ },
	{ text: "Tickets", disabled: true, external: true },
];
