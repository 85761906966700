import { h } from 'preact';
import htm from 'htm';

import { palette } from '../../branding';

const html = htm.bind(h);

const Section = ({
	backgroundImage,
	backgroundSize = 'cover',
	height,
	alt,
	just,
	children,
	banner,
	bigbanner,
	nopadding,
	id,
}) => {
	// Shared
	let style = {
		padding: nopadding ? 0 : 40,
		background: palette.secondary,
		'min-height': 200,
		'box-sizing': 'border-box',

		display: 'flex',
		'justify-content': 'center',
		'align-items': 'center',
		'flex-direction': 'column',
		'flex-wrap': 'nowrap',
		'text-align': 'center',
	};
	if (height) {
		style.height = height;
	}
	if (bigbanner) {
		style['min-height'] = 560;
	} else if (banner) {
		style['min-height'] = 400;
	}
	if (just) {
		style['text-align'] = 'justify';
	}

	if (alt) {
		style = {
			...style,
			...{
				background: palette.primary,
				color: palette.accent,
			},
		};
	}

	style = {
		...style,
		'background-size': backgroundSize,
		'background-position': 'center',
	};

	const anchorStyle = {
		display: 'block',
		position: 'relative',
		top: -150,
		visibility: 'hidden',
	};

	if (backgroundImage) {
		style.background = palette.primary; // Prevents white flash
		style['background-image'] = `url(${backgroundImage})`;
	}

	return html`<section style=${style}>
		${id && html`<a style=${anchorStyle} id=${id}></a>`}
		${children}
	</section>`;
};
const FloatingSection = ({
	alt,
	children,
	id,
}) => {
	// Shared
	let style = {
		padding: '20px 20px 5px 20px',
		margin: '20px 5px',
		'border-radius': 5,
		background: palette.secondary,
		'min-height': 200,
		'box-sizing': 'border-box',
		display: 'flex',
		'justify-content': 'center',
		'align-items': 'center',
		'flex-direction': 'column',
		'flex-wrap': 'nowrap',
		'text-align': 'center',
	};

	if (alt) {
		style = {
			...style,
			...{
				background: palette.primary,
				color: palette.accent,
			},
		};
	}

	const anchorStyle = {
		display: 'block',
		position: 'relative',
		top: -150,
		visibility: 'hidden',
	};

	return html`<section style=${style}>
		${id && html`<a style=${anchorStyle} id=${id}></a>`}
		${children}
	</section>`;
};

const SectionHead = ({ text = '', shadow, smallFont }) => {
	const style = {
		color: palette.primary2,
		'font-family': 'Planet Gamers',
		'font-size': 50,
		width: '100%',
		'text-align': 'center',
	};
	if (shadow) {
		style['text-shadow'] = `-2px 2px ${palette.primary}`;
	}
	if (smallFont) {
		style['font-size'] = 32;
	}

	return html`<div style=${style}>${text}</div> `;
};

const SectionNarrow = ({ justify, children }) => {
	var style = {
		width: '100%',
		'max-width': 720,
		margin: 'auto',
		'text-align': justify ? 'justify' : 'center',
	};

	return html`<p style=${style}>${children}</p>`
};

export { Section, FloatingSection, SectionHead, SectionNarrow };
