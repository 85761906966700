import { h } from 'preact';
import htm from 'htm';

import { Band } from '../atoms/band';
import { Section, SectionHead, SectionNarrow } from '../molecules/section';
import getInvolvedHead from './getinvolved-head.jpg';

const html = htm.bind(h);

export default () => html`
	<${Section} banner backgroundImage=${getInvolvedHead}>
		<${SectionHead} text="Get Involved" shadow />
	</${Section}>
	<${Section}>
		<${SectionHead} text="ExhIbItors" />
		<${SectionNarrow} justify>
			<p>Are you interested in exhibiting with us in 2025? Exhibitor spaces for 2025 will be <b><i>available soon</i></b>. Contact <a href="mailto: stacey@norwichgames.uk">Stacey Sanderson</a> if you're interested and we will keep you updated!</p>

			<p>In 2023 and 2024 we hosted around 60 exhibitors and received a fantastic response, and we'd love to have you join us for the THIRD YEAR of NGC!</p>
		</${SectionNarrow}>
	</${Section}>
	<${Section} alt>
		<${SectionHead} text="RPG Game Masters" />
		<${SectionNarrow} justify>
			<p>In 2025 we'll be hosting another big day of games, including some special guests and some games in an extra special location. RPG event signups will be open soon, so if you're interested in hosting a roleplaying game in 2025 please watch this space and our socials, as we will post there once signups are open.</p>

			<p>RPG Game Masters will get free VIP entry to the convention.</p>
		</${SectionNarrow}>
	</${Section}>
	<${Section}>
		<${SectionHead} text="Volunteers" />
		<${SectionNarrow} justify>
			<p>NGC is all about community, and our group of tightly-knit volunteers are the friendliest around. If you're interested in joining us to help make NGC a success please watch this space and our socials to see when signups are open. We ask that volunteers be willing to give around 6 hours of their time on the day, but it's unlikely we'll be asking for more than about 4 hours of light work. All volunteers will be paired with someone who has previously been involved with the organisation and running of Norwich Games Convention.</p>

			<p>Volunteers get VIP entry to the convention, exclusive swag, paid lunch, and anything else we can think of to say thankyou!</p>
		</${SectionNarrow}>
	</${Section}>
	<${Band} />
`;
