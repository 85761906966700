import { h } from 'preact';
import htm from 'htm';

import { palette } from '../branding';
import { Band } from '../atoms/band';
import { Section, SectionHead, SectionNarrow } from '../molecules/section';

import aboutHead from './about-head.jpg';
import imgJA from '../img/profiles/ja.jpg';
import imgSS from '../img/profiles/ss.jpg';
import imgBF from '../img/profiles/bf.jpg';
import imgAF from '../img/profiles/af.jpg';
import imgKS from '../img/profiles/ks.jpg';
import imgES from '../img/profiles/es.jpg';
import imgGM from '../img/profiles/gm.jpg';

const html = htm.bind(h);

const meStyle = {
	'text-align': 'justify',
};

const Profile = ({
	img,
	name,
	title,

	children,
}) => {
	const nameStyle = {
		color: palette.primary2,
		'font-family': 'Planet Gamers',
		'font-size': 32,
		margin: '40px 0px 0px 0px',
	};
	const titleStyle = {
		color: palette.secondary,
		'font-family': 'Planet Gamers',
		'font-size': 20,
		margin: '0px 0px 40px 0px',
	};
	const boxStyle = {
		margin: '20px 0px',
		width: '100%',
	};

	return html`<${SectionNarrow}>
		<div style=${boxStyle}>
			<img src=${img} />
			<h2 style=${nameStyle}>${name}</h2>
			<h2 style=${titleStyle}>${title}</h2>
			${children}
		</div>
	</${SectionNarrow}>`;
};

export default () => html`
	<${Section} banner backgroundImage=${aboutHead}>
		<${SectionHead} text="About" shadow />
	</${Section}>
	<${Section}>
		<${SectionHead} text="How it all began" />
		<${SectionNarrow} justify>
			<p>Norwich Games Con was conceived of sometime around 2015-2016, when we started our own mini-convention for a few friends (and friends of friends) to get together for a long weekend of games. We had so many RPGs and board games that we wanted to share with friends and family - and so many friends who wanted to share their games with us - that something had to happen. We setup multiple tables and arranged games in four-hour slots over three days, and it was a hit that grew each year until 2020.</p>

			<p>During the pandemic we moved online and kept the dream alive, but missed the face-to-face experience. Over the years, so many people had said to us that Norwich was badly in need of a full-scale convention, and we agreed. Time to scale up our little gathering.</p>
		</${SectionNarrow}>
	</${Section}>
	<${Section} alt>
		<${SectionHead} text="The Fellow­ship" />
		<${Profile} img=${imgJA} name="John Anthony" title="Founder">
			<p style=${meStyle}>I'm a software engineer, tinkerer and hobbyist game designer from a little town called Braintree in Essex. I managed to get ahold of a 1981 Basic set of Dungeons & Dragons and a copy of the "three little black books" original version of Traveller back in the 90s (both already old then) and have been hooked ever since. I love lots of RPGs from smaller publishers, community-made games, retroclones, and the difficult-to-find and out-of-print.</p>

			<p style=${meStyle}>Oh, and I'm still running '81 D&D and original Traveller over at <a href="https://www.facebook.com/TheGamesTableNorwich" target="_blank">The Games Table</a>! Come and join me sometime!</p>
		</${Profile}>
		<${Profile} img=${imgSS} name="Stacey Sanderson" title="Operations Manager">
			<p style=${meStyle}>I was born locally in Gorleston, and have been a huge fan of board games for as long as I can remember. It's hard to pick a favourite board game, but if I had to pick one that started my mild obsession, it would be Ticket to Ride. I enjoy it so much I entered a tournament a few years ago, and ranked exactly centre... I was happy with that!</p>

			<p style=${meStyle}>I also enjoy playing RPGs. My favourite of these is Dungeon Crawl Classics, which I enjoy running AND playing! I am also a huge fan of Traveller, Dragonlance, and anything Fighting Fantasy.</p>
		</${Profile}>
		<${Profile} img=${imgBF} name="Ben Fleetham" title="Crypt Keeper">
			<p style=${meStyle}>Good evening <i>boils and ghouls</i>! During the day, and especially at night, the Crypt Keeper here will be keeping an eye on RPG events. We could have given him a more traditional title like Room Captain or perhaps <i>master of scareimonies</i>, but all RPG events in 2023 took place in The Crypt and we're weren't about to miss that opportunity.</p>

			<p style=${meStyle}>Ben's an educator and upstanding citizen in his everyday life. Who better to maintain discipline at the table?</p>
		</${Profile}>
		<${Profile} img=${imgAF} name="Alex Fleetham" title="Man-at-Arms">
			<p style=${meStyle}>Improvise. Adapt. Overcome. Alex is our problem-solver. I mean... clearly nothing's going to go wrong, but when you've got a knight in shining armour like this ready to help out you can just be a little more confident.</p>
		</${Profile}>
		<${Profile} img=${imgKS} name="Kieran Sanderson" title="Troll Wrangler">
			<p style=${meStyle}>Kieran has never had a bad day in his life. We put him on the doors for NGC '23 and three times as many people as we'd planned for showed up. He had a great time. That kind of irrepressible cheerfulness - combined with unerring ability to give correct change - is why he's now shackled to the ticket desk at every NGC for the forseeable future.</p>

			<p style=${meStyle}>Oh and also he does our social media (hence the title).</p>
		</${Profile}>
		<${Profile} img=${imgES} name="Elliot Symonds" title="Orctioneer">
			<p style=${meStyle}>Ever heard of a reverse sealed-envelope silent laddered tights auction? Elliot probably has. Do you know about the rare boxes of 1996 edition Advanced Hero Quest with rude jokes accidentally printed on the potion cards? If that's a real thing we wouldn't be surprised to hear that Elliot's got two to sell.</p>

			<p style=${meStyle}>He's passionate about getting games into the hands of people who are going to enjoy them, and having fun doing it. We couldn't ask for a better person to be running auctions.</p>
		</${Profile}>
		<${Profile} img=${imgGM} name="Grey Mouser" title="Staff Morale Officer">
			<p style=${meStyle}>Despite a number of high-profile illnesses, we're happy to announce that Monsieur G. Rattus Attackus Mouser MSc (Hons) will be providing both pest control and staff morale services.</p>
		</${Profile}>
	</${Section}>
	<${Band} colour=${palette.secondary} />
`;
